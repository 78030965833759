:root {
  --color-bg: #b7ffbf;
  --color-fg: #111;
  --cf-1: #53257d;
  --cf-2: #dd7380;
  --cf-3: #f55924;
  --cf-4: #dbc1ff;
  scrollbar-color: var(--color-fg) var(--color-bg);
}

/* Secret theme to not draw attention while coding :smiling_imp: */
.theme-incognito {
  --color-bg: #160c3f;
  --color-fg: #eee;
  --color-separator: #eee;
  color-scheme: dark;
}

.theme-darkest-night {
  --color-bg: #050213;
  --color-fg: rgb(237, 216, 216);
  --color-separator: rgb(246, 188, 157);
  color-scheme: dark;
}

.theme-og {
  --color-bg: #b7ffbf;
  --color-fg: #111;
}

.theme-sea-salt {
  --color-bg: #c5f8ff;
  --color-fg: rgb(31, 31, 77);
}

.theme-vanilla {
  --color-bg: #f9f8e6;
  --color-fg: #5e503f;
}

.theme-picker {
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
}

.theme-picker-button {
  cursor: pointer;
  min-width: 44px;
  min-height: 44px;
  border-radius: 100%;
  border: 4px solid var(--color-fg);
  background: var(--color-bg);
}

.theme-picker-button-half-1 {
  background-color: var(--color-bg);
}
.theme-picker-button-half-2 {
  background-color: var(--color-fg);
}

h1,
h2,
h3 {
  font-family: "WildBerry", sans-serif;
  font-weight: 700;
  line-height: 1.25;
}

html {
  height: 100%;
  background-color: var(--color-bg);
  color: var(--color-fg);
}

body {
  margin: 0;
  max-width: 64rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  font-size: 1.125rem;
  font-family: sans-serif;
  line-height: 1.4;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

header {
  padding: 1rem;
  text-align: center;
}

footer {
  margin-top: auto;
  padding-top: 1rem;
  text-align: center;
  font-size: 1rem;
}

.Months > * + * {
  margin-top: 2rem;
}

.Grid {
  display: grid;
  margin: 0;
  padding: 0;
  list-style: none;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
}

.Grid-Item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-separator, #111111);
  border-radius: 4px;
  height: 8rem;
  gap: 0.5em;
  text-align: center;
}

.Grid-Item {
  scroll-margin-block-start: 1rem;
}

.Grid-Item a {
  color: currentColor;
}

.Grid-Item:target {
  outline-offset: 2px;
  outline: 2px solid #191919;
}

.Grid-Item:focus {
  outline-offset: 2px;
  outline: 2px solid #191919;
}

.Grid-Item:focus:not(:focus-visible) {
  outline: none;
}

.Grid-Item:focus-visible {
  outline-offset: 2px;
  outline: 2px solid #191919;
}

.Grid-Item > .emoji {
  font-size: 2em;
}

.vampire {
  opacity: 0.3;
  transition: opacity 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  min-width: 44px;
  min-height: 44px;
  font-size: 1.125rem;
  background: none;
  border: 1px solid var(--color-separator, #111111);
  border-radius: 50%;
}

.vampire[aria-pressed="true"] {
  opacity: 1;
}

.Occasion-label {
  font-weight: 700;
}

.confetti-cannon {
  display: block;
  padding: 0.5em;
  border: 1px solid var(--color-separator, #111111);
  /* background-color: #af8fdc; */
  color: #111;
  background: #eee;
  border-radius: 4px;
  transition: background-color 0.12s cubic-bezier(0.075, 0.82, 0.165, 1);
  user-select: none;
  cursor: pointer;
}

/* Progressively-enhance :focus-visible */
.confetti-cannon:focus {
  outline: 2px solid transparent;
  border-color: black;
  box-shadow: 0 0 0 2px var(--color-fg);
}

.confetti-cannon:focus:not(:focus-visible) {
  outline: none;
  border-color: var(--c-accent);
  box-shadow: none;
}

.confetti-cannon:focus-visible {
  outline: 2px solid transparent;
  border-color: black;
  box-shadow: 0 0 0 2px var(--color-fg);
}

/* Style active, do not hide box-shadow (and thus focus style) */
.confetti-cannon:active {
  background-color: #dbc1ff;
}

/* Style active, hide box-shadow (and thus focus style) */
.confetti-cannon:active:not(:focus-visible) {
  background-color: #dbc1ff;
  box-shadow: none;
}
